import React from "react";
import { BymNumberInput, BymTextInput, required } from "components/Common/Forms";
import { Row, Col, Alert } from "react-bootstrap";
import BymFormConditional from "components/Common/Forms/BymFormConditional";
import BymFormValueWrapper from "components/Common/Forms/BymFormValueWrapper";
import { KontraktLeieObjekt, LeieObjekt } from "interfaces";
import { filter, map } from "lodash-es";
import UgeDateInput from "components/Common/Forms/UgeDateInput";
import { AvtaleData, UIState } from "./KontraktAvtaleUtils";
import BymCurrencyInput from "components/Common/Forms/BymCurrencyInput";

const RiggPlassPrisInfo = ({
  kontraktLeieobjekter = [],
  allLeieobjekter,
  currentValues
}: {
  kontraktLeieobjekter?: KontraktLeieObjekt[];
  allLeieobjekter?: LeieObjekt[];
  currentValues?: AvtaleData;
}) => {
  const checkedItems = filter(kontraktLeieobjekter, (i: any) => i.checked);
  const isChecked = (leieobjektId: any) =>
    filter(checkedItems, (item: any) => item.leieObjektId === leieobjektId).length > 0;

  return (
    <>
      <Row>
        <Col md={12}>
          <br />
          <strong>Legg inn prisinfo for valgte leieobjekter</strong>
          <small style={{ margin: "4px 0 10px 0", display: "block" }}>
            Fra/Til dato trenger kun å fylles ut dersom leieobjektet har andre fra/til datoer enn det som er lagt inn
            under varighet ovenfor
          </small>
          <table className="timeleie-table">
            <thead>
              <tr>
                <th>Leieobjekt</th>
                <th>Fra dato</th>
                <th>Til dato</th>
                <th>Pris pr kvm</th>
                <th>{currentValues?.avtaleData?.leiesumType === "2" ? "Totalsum" : "Årlig sum"}</th>
              </tr>
            </thead>
            <tbody>
              {checkedItems.length === 0 ? (
                <tr>
                  <td colSpan={5}>
                    <Alert bsStyle="warning">Ingen leieobjekter valgt</Alert>
                  </td>
                </tr>
              ) : (
                <>
                  {map(allLeieobjekter, (i, index) => {
                    if (!isChecked(i.id)) return null;
                    return (
                      <tr>
                        <td>
                          <BymTextInput controlId={`kontrakt.leieObjekter[${index}].navn`} hidelabel readOnly />
                        </td>
                        <td>
                          <div>
                            <UgeDateInput
                              controlId={`kontrakt.leieObjekter[${index}].gyldigFraDato`}
                              hidelabel
                              placeholder=""
                              viewDate={new Date()}
                              alignRight={false}
                            />
                          </div>
                        </td>
                        <td>
                          <UgeDateInput
                            controlId={`kontrakt.leieObjekter[${index}].utlopDato`}
                            hidelabel
                            placeholder=""
                            viewDate={new Date()}
                            alignRight={false}
                          />
                        </td>
                        <td>
                          <BymCurrencyInput
                            controlId={`kontrakt.leieObjekter[${index}].kvmPris`}
                            hidelabel
                            rules={[required()]}
                          />
                        </td>
                        <td>
                          {currentValues?.avtaleData?.leiesumType === "1" && (
                            <BymCurrencyInput
                              controlId={`kontrakt.leieObjekter[${index}].pris`}
                              hidelabel
                              rules={[required()]}
                            />
                          )}
                          {currentValues?.avtaleData?.leiesumType === "2" && (
                            <BymCurrencyInput
                              controlId={`kontrakt.leieObjekter[${index}].totalPris`}
                              hidelabel
                              rules={[required()]}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <BymCurrencyInput controlId="avtaleData.kvmSum" label="Leiesum pr kvadratmeter" />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <BymCurrencyInput controlId="avtaleData.fakturaSum" label="Leiesum pr faktura" />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <BymCurrencyInput controlId="avtaleData.dagMulkt" label="Dagmulkt" />
          <br />
          <br />
        </Col>
      </Row>
    </>
  );
};

type PrisInfoProps = {
  uiState: UIState;
  leieObjekter?: LeieObjekt[];
  currentValues?: AvtaleData;
};

const PrisInfo = ({ uiState, leieObjekter, currentValues }: PrisInfoProps) => (
  <>
    <BymFormConditional<AvtaleData> comparator={formValues => formValues?.avtaleData?.kontraktsMal === "2"}>
      <BymFormValueWrapper<AvtaleData>
        mapFunction={formValues => ({
          kontraktLeieobjekter: formValues?.kontrakt?.leieObjekter,
          useTotalPris: formValues?.avtaleData?.leiesumType === "2"
        })}
      >
        <RiggPlassPrisInfo allLeieobjekter={leieObjekter} currentValues={currentValues} />
      </BymFormValueWrapper>
    </BymFormConditional>
    <BymFormConditional<AvtaleData> comparator={formValues => formValues?.avtaleData?.kontraktsMal !== "2"}>
      <Row>
        <Col md={10}>
          <BymFormConditional<AvtaleData> comparator={formValues => formValues?.avtaleData?.leiesumType === "1"}>
            <BymCurrencyInput controlId="avtaleData.aarligSum" label="Årlig leie ekskl. mva (kr)" />
          </BymFormConditional>
          <BymFormConditional<AvtaleData> comparator={formValues => formValues?.avtaleData?.leiesumType === "2"}>
            <BymCurrencyInput controlId="avtaleData.totalSum" label="Totalsum" />
            <BymCurrencyInput
              controlId="avtaleData.aarligSum"
              label="Beregnet årlig sum"
              placeholder="brukes ved fakturering (endre manuelt hvis feil)"
            />
          </BymFormConditional>
          {(uiState.showAarligPrisType === "aarlig" || uiState.showAarligPrisType === undefined) && (
            <>
              <BymCurrencyInput controlId="avtaleData.fakturaSum" label="Leiesum pr faktura" />
              <BymCurrencyInput controlId="avtaleData.kvmSum" label="Leiesum pr kvadratmeter" />
            </>
          )}
          {uiState.showAarligPrisType === "aarligUtenKvadratmetersum" && (
            <BymCurrencyInput controlId="avtaleData.fakturaSum" label="Leiesum pr faktura" />
          )}
          {uiState.showAarligPrisType === "aarligMedParkering" && (
            <>
              <Row>
                <Col md={2}>
                  <BymNumberInput controlId="avtaleData.etasje" label="Etasje" />
                </Col>
                <Col md={4}>
                  <BymNumberInput controlId="avtaleData.parkeringsplasser" label="Antall parkeringsplasser" />
                </Col>
                <Col md={3}>
                  <BymCurrencyInput controlId="avtaleData.lokalerSum" label="Lokaler sum" />
                </Col>
                <Col md={3}>
                  <BymCurrencyInput controlId="avtaleData.parkeringsplasserSum" label="Parkeringsplasser sum" />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <BymNumberInput controlId="avtaleData.utleierKontonummer" label="Utleiers kontonummer" />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </BymFormConditional>
  </>
);

export default PrisInfo;
