import { Eiendom, Kontrakt, KontraktLeieObjekt, LeieObjekt } from "interfaces";
import { useEffect, useState } from "react";
import { get, merge } from "lodash-es";
import { produce } from "immer";
import { useSelector } from "react-redux";

const kontraktTemplates = [
  { id: "1", disabled: false, name: "Leie av areal" },
  { id: "2", disabled: false, name: "Leie av riggplass" },
  { id: "3", disabled: false, name: "Leie av lokale på eiendom med flere brukere" },
  { id: "4", disabled: false, name: "Timeleie av idrettsanlegg" },
  { id: "5", disabled: false, name: "Timeleie av lokaler" },
  { id: "6", disabled: false, name: "Leiekontrakt - bruk av bad" },
  { id: "7", disabled: false, name: "Leie av hytte" },
  { id: "8", disabled: false, name: "Leie av bolig" },
  { id: "9", disabled: false, name: "Leie lokale/eiendom med en bruker" },
  { id: "10", disabled: false, name: "Leie av tjenestebolig med boplikt" },
  { id: "11", disabled: false, name: "Leie av tjenestebolig" }
]; // as const not working!

type TemplateIds = typeof kontraktTemplates[number]["id"];
export type KontraktTemplate = typeof kontraktTemplates[0];
export type KontraktTemplates = typeof kontraktTemplates;

export const fremLeieOptions = [
  {
    id: "1",
    name: "Fremleie eller fremlån av leieobjektet eller deler av leieobjektet er ikke tillatt uten utleiers skriftlige forhåndssamtykke."
  },
  {
    id: "2",
    name: "Fremleie av leieobjektet er kun tillatt til de aktører leietaker benytter seg av til avtalt utnyttelse av leieobjektet, i samsvar med avtalens formål"
  }
];

export const tvistOptions = [
  {
    id: "1",
    name: "Tvistespørsmål mellom utleier og leietaker skal søkes løst i minnelighet. Dersom dette er mulig, er Oslo tingrett verneting."
  },
  {
    id: "2",
    name: "Oppstår uenighet angående leieforholdet, bringes spørsmålet inn for de respektive byrådsavdelinger. Avgjørelse tas av kompetent kommunalt organ."
  }
];

type KontraktsMalType = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11";

export type DokumentData = {
  /**
   * - "1" = Leie av areal
   * - "2" = Leie av riggplass
   * - "3" = Leie av lokale på eiendom med flere brukere
   * - "4" = Timeleie av idrettsanlegg
   * - "5" = Timeleie av lokaler
   * - "6" = Leiekontrakt - bruk av bad
   * - "7" = Leie av hytte
   * - "8" = Leie av bolig
   * - "9" = Leie lokale/eiendom med en bruke
   * - "10" = Leie av tjenestebolig med boplikt
   * - "11" = Leie av tjenestebolig
   */
  kontraktsMal?: KontraktsMalType;
  fremLeieOption?: "1" | "2";
  kontaktpersonBymAdresse?: string;
  kontaktpersonBymNavn?: string;
  kontaktpersonBymEpost?: string;
  tvistOption?: "1" | "2";
  forfall?: string;
  vedlegg?: {
    name: string;
    allowDelete: boolean;
  }[];
  antallEksemplarer?: number | string;
  kontraktsMalLabel?: string;
  /**
   * - "1" = Årspris
   * - "2" = Totalpris
   * */
  leiesumType?: "1" | "2";
  boplikt?: "0" | "1";
  kvmPrisPeriode?: "0" | "1" | "2";
  signeresDigitalt?: "True" | "False";
  [key: string]: any;
};

export type AvtaleData = {
  kontrakt: Partial<Kontrakt>;
  avtaleData: DokumentData;
};

const emptyKontraktAvtale: AvtaleData = {
  kontrakt: {
    betalingTerminId: 1, // månedlig (bør den settes?)
    faktureringTidspunktId: "501", // forskudd
    faktureringTypeId: "31002", // ekstern
    garantiTypeId: 2, // depositum
    kontraktStatusId: 1, // under behandling
    kontraktTypeId: "27002", // areal (bør ikke hardkodes)
    segmentId: 2, // ???
    leieObjekter: [],
    prisTypeId: 1 // ikke sesongbasert
  },
  avtaleData: {
    fremLeieOption: "1",
    kontaktpersonBymAdresse: "Karvesvingen 3, 0579 OSLO",
    tvistOption: "1",
    forfall: "28",
    vedlegg: [{ name: "Kartskisse", allowDelete: false }],
    antallEksemplarer: 2,
    kvmPrisPeriode: "0"
  }
};

export type TimeleieType = "timeleie" | "timeleieGarderobe" | "timeleieBetjening";

export type UIState = {
  showBygningsnummer?: boolean;
  showDepositum?: "depositumkonto" | "depositumEllerGaranti" | "sumOnly";
  showForsteBetaling?: "full" | "dateOnly";
  showForskuddEtterskudd?: boolean;
  showBygningBruk?: boolean;
  showTittelFormaal?: boolean;
  showTittelNavn?: boolean;
  showAarligTotalPris?: boolean;
  showAarligPrisType?: "aarlig" | "aarligUtenKvadratmetersum" | "aarligMedParkering";
  showFakturaFrekvens?: boolean;
  showOvertakelse?: boolean;
  showOrdensregler?: boolean;
  showAntallNokler?: boolean;
  showFelleskostnader?: boolean;
  showKommune?: boolean;
  showFratrekk?: boolean;
  showTimeleiePris?: TimeleieType;
  showHusordensregler?: boolean;
  showInkludertILeien?: boolean;
  showBoplikt?: boolean;
  showKvmPrisPeriode?: boolean;
  showSigneringValg?: boolean;
  hideVarighet?: boolean;
  hideFremleie?: boolean;
  hideOppsigelsetid?: boolean;
  hideTvistelosning?: boolean;
  hideBrukAvLeieobjekt?: boolean;
  supportsTidsubestemt?: boolean;
};

const getTemplateDefaults = (templateId: TemplateIds): { defaultData: AvtaleData; templateUIState: UIState } => {
  switch (templateId) {
    case "1": // leie av areal
      return {
        defaultData: { kontrakt: {}, avtaleData: { leiesumType: "1", signeresDigitalt: "True" } },
        templateUIState: {
          showAarligTotalPris: true,
          showFakturaFrekvens: true,
          showTittelFormaal: true,
          showKvmPrisPeriode: true,
          showSigneringValg: true
        }
      };
    case "2": // leie av riggplass
      return {
        defaultData: { kontrakt: {}, avtaleData: { leiesumType: "1", signeresDigitalt: "True" } },
        templateUIState: {
          showDepositum: "depositumEllerGaranti",
          showForsteBetaling: "full",
          showForskuddEtterskudd: true,
          showAarligTotalPris: true,
          showFakturaFrekvens: true,
          supportsTidsubestemt: true,
          showKvmPrisPeriode: true,
          showSigneringValg: true
        }
      };
    case "3": // Leie av lokale på eiendom med flere brukere
      return {
        defaultData: {
          avtaleData: {
            leiesumType: "1",
            forfallFelleskostnader: "1",
            vedlegg: [
              { name: "Kartskisse", allowDelete: false },
              { name: "Felleskostnader", allowDelete: false }
            ],
            signeresDigitalt: "True"
          },
          kontrakt: {}
        },
        templateUIState: {
          showBygningsnummer: true,
          showForskuddEtterskudd: true,
          showForsteBetaling: "dateOnly",
          showAarligPrisType: "aarligMedParkering",
          showOvertakelse: true,
          showFakturaFrekvens: true,
          showTittelFormaal: true,
          showFelleskostnader: true,
          showKommune: true,
          showSigneringValg: true,
          hideOppsigelsetid: true,
          hideFremleie: true
        }
      };
    case "4": // Timeleie av idrettsanlegg
      return {
        defaultData: {
          avtaleData: {
            leiesumType: "1",
            vedlegg: [{ name: "Kartskisse", allowDelete: false }],
            signeresDigitalt: "False"
          },
          kontrakt: {}
        },
        templateUIState: {
          showBygningsnummer: true,
          showFakturaFrekvens: true,
          showAarligTotalPris: true,
          showTittelFormaal: true,
          showTittelNavn: true,
          showFratrekk: true,
          showInkludertILeien: true,
          showTimeleiePris: "timeleieGarderobe"
        }
      };
    case "5": // Timeleie av lokaler
      return {
        defaultData: {
          avtaleData: {
            leiesumType: "2",
            vedlegg: [{ name: "Kartskisse", allowDelete: false }],
            signeresDigitalt: "False"
          },
          kontrakt: {}
        },
        templateUIState: {
          showBygningsnummer: true,
          showTittelFormaal: true,
          showTittelNavn: true,
          showDepositum: "sumOnly",
          showTimeleiePris: "timeleie",
          hideVarighet: true,
          hideFremleie: true,
          showInkludertILeien: true
        }
      };
    case "6": // Timeleie av bad
      return {
        defaultData: {
          avtaleData: {
            leiesumType: "2",
            vedlegg: [
              { name: "Ordensregler", allowDelete: false },
              { name: "Brann - og rømningsinstruks", allowDelete: false }
            ],
            signeresDigitalt: "False"
          },
          kontrakt: {}
        },
        templateUIState: {
          showBygningsnummer: true,
          showTittelFormaal: true,
          showTittelNavn: true,
          showForskuddEtterskudd: true,
          showFakturaFrekvens: true,
          showTimeleiePris: "timeleieBetjening",
          showFratrekk: true
        }
      };
    case "7": // leie av hytte
      return {
        defaultData: {
          kontrakt: {
            betalingTerminId: 1
          },
          avtaleData: {
            leiesumType: "1",
            signeresDigitalt: "False"
          }
        },
        templateUIState: {
          showBygningsnummer: true,
          showForskuddEtterskudd: true,
          showFakturaFrekvens: true,
          showForsteBetaling: "full",
          showDepositum: "depositumkonto",
          showBygningBruk: true,
          showTittelFormaal: true,
          showAarligPrisType: "aarligUtenKvadratmetersum"
        }
      };
    case "8": // leie av bolig
      return {
        defaultData: {
          kontrakt: {
            betalingTerminId: 1
          },
          avtaleData: {
            leiesumType: "1",
            signeresDigitalt: "True"
          }
        },
        templateUIState: {
          showBygningsnummer: true,
          showForsteBetaling: "dateOnly",
          showDepositum: "depositumkonto",
          showAarligPrisType: "aarligUtenKvadratmetersum",
          showOvertakelse: true,
          showAntallNokler: true,
          showHusordensregler: true,
          showSigneringValg: true,
          hideOppsigelsetid: true,
          hideFremleie: true,
          hideTvistelosning: true,
          hideBrukAvLeieobjekt: true
        }
      };
    case "9": // Leie lokale/eiendom med en bruker
      return {
        defaultData: {
          avtaleData: {
            leiesumType: "1",
            signeresDigitalt: "True"
          },
          kontrakt: {}
        },
        templateUIState: {
          showBygningsnummer: true,
          showForskuddEtterskudd: true,
          showAarligPrisType: "aarligMedParkering",
          showOvertakelse: true,
          showFakturaFrekvens: true,
          showTittelFormaal: true,
          showSigneringValg: true,
          hideFremleie: true,
          hideOppsigelsetid: true
        }
      };
    case "10": // leie av tjenestebolig med boplikt
      return {
        defaultData: {
          kontrakt: {
            betalingTerminId: 1
          },
          avtaleData: {
            leiesumType: "1",
            signeresDigitalt: "True"
          }
        },
        templateUIState: {
          showBygningsnummer: true,
          showForsteBetaling: "dateOnly",
          showDepositum: "depositumkonto",
          showAarligPrisType: "aarligUtenKvadratmetersum",
          showOvertakelse: true,
          showAntallNokler: true,
          showHusordensregler: true,
          showSigneringValg: true,
          hideOppsigelsetid: true,
          hideFremleie: true,
          hideTvistelosning: true,
          hideBrukAvLeieobjekt: true
        }
      };
    case "11": // leie av tjenestebolig
      return {
        defaultData: {
          kontrakt: {
            betalingTerminId: 1
          },
          avtaleData: {
            leiesumType: "1",
            boplikt: "0",
            signeresDigitalt: "True"
          }
        },
        templateUIState: {
          showBygningsnummer: true,
          showForsteBetaling: "dateOnly",
          showDepositum: "depositumkonto",
          showAarligPrisType: "aarligUtenKvadratmetersum",
          showOvertakelse: true,
          showAntallNokler: true,
          showHusordensregler: true,
          showBoplikt: true,
          showSigneringValg: true,
          hideOppsigelsetid: true,
          hideFremleie: true,
          hideTvistelosning: true,
          hideBrukAvLeieobjekt: true
        }
      };
    default:
      return {
        defaultData: {
          avtaleData: {},
          kontrakt: {}
        },
        templateUIState: {}
      };
  }
};

export function useKontraktAvtale(kontraktData?: Kontrakt, eiendom?: Eiendom, leieObjekter: LeieObjekt[] = []) {
  const login = useSelector<any, any>(state => state.login);
  const [uiState, setUiState] = useState<UIState>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [templateIsSelected, setTemplateIsSelected] = useState(false);
  const [kontraktAvtale, setKontraktAvtale] = useState<AvtaleData>({
    kontrakt: { ...emptyKontraktAvtale.kontrakt },
    avtaleData: {
      ...emptyKontraktAvtale.avtaleData,
      kontaktpersonBymNavn: login.sub,
      kontaktpersonBymEpost: login.email
    }
  });

  const changeTemplate = (formAvtaleData: AvtaleData): AvtaleData => {
    if (!formAvtaleData?.avtaleData?.kontraktsMal) return formAvtaleData;

    setTemplateIsSelected(true);
    const { templateUIState, defaultData } = getTemplateDefaults(formAvtaleData.avtaleData.kontraktsMal);
    setUiState(templateUIState);
    const returnData = merge(formAvtaleData, defaultData);
    returnData.avtaleData.vedlegg = defaultData.avtaleData.vedlegg || [{ name: "Kartskisse", allowDelete: false }];
    const index = Number(get(formAvtaleData, "avtaleData.kontraktsMal")) - 1;
    returnData.avtaleData.kontraktsMalLabel = kontraktTemplates[index].name;
    return returnData;
  };

  /**
   * Returns a list of all available leieobjekter for the kontrakt merged with the
   * values from existing leieobjekter in the kontrakt
   */
  const getKontraktLeieObjekter = (kontrakt?: Partial<Kontrakt>, availableLeieObjekter?: LeieObjekt[]) =>
    availableLeieObjekter?.map(lo => {
      const kontraktLeieObjekt = kontrakt?.leieObjekter?.filter(klo => klo.leieObjektId === lo.id)[0];
      return {
        leieObjektId: lo.id,
        navn: lo.navn,
        pris: kontraktLeieObjekt ? kontraktLeieObjekt.pris : null,
        pris2: kontraktLeieObjekt ? kontraktLeieObjekt.pris2 : null,
        fakturaTekst: kontraktLeieObjekt ? kontraktLeieObjekt.fakturaTekst : null,
        antall: kontraktLeieObjekt ? kontraktLeieObjekt.antall : 1,
        checked: kontraktLeieObjekt !== undefined,
        timeLeie: [{}]
      } as KontraktLeieObjekt;
    });

  useEffect(() => {
    if (kontraktData || eiendom || leieObjekter.length > 0) {
      if (kontraktData && !isEditMode) setIsEditMode(true);
      if (kontraktData && kontraktData.kontraktMalId && !templateIsSelected) setTemplateIsSelected(true);
      setKontraktAvtale(
        produce((draft: AvtaleData) => {
          if (kontraktData) {
            const { templateUIState, defaultData } = getTemplateDefaults(
              kontraktData.kontraktMalId?.toString() as string
            );
            setUiState(templateUIState);
            draft.kontrakt = { ...kontraktData };
            draft.avtaleData.kontraktsMal = kontraktData.kontraktMalId?.toString() as KontraktsMalType;
            draft.avtaleData.kontraktsMalLabel = kontraktData.kontraktMal?.navn;
            draft.avtaleData.leietakerType = kontraktData.kundenummer ? "Kundenummer" : undefined;
            draft.avtaleData.signeresDigitalt = defaultData.avtaleData.signeresDigitalt;
            draft.avtaleData.leiesumType = defaultData.avtaleData.leiesumType;
          }
          if (eiendom) {
            draft.kontrakt.eiendomId = eiendom.id;
          }
          if (leieObjekter.length > 0) {
            draft.kontrakt.leieObjekter = getKontraktLeieObjekter(kontraktData, leieObjekter);
          }
        })
      );
    }
  }, [eiendom, isEditMode, kontraktData, leieObjekter, leieObjekter.length, templateIsSelected]);

  return {
    kontraktTemplates,
    fremLeieOptions,
    tvistOptions,
    initialAvtaleData: kontraktAvtale,
    templateIsSelected,
    isEditMode,
    uiState,
    changeTemplate
  };
}
