export default function Release6() {
  return (
    <div style={{ marginTop: "10px", marginBottom: "30px" }}>
      <h5 style={{ fontSize: "1.5em" }}>Oppdaterte kontraktmaler</h5>
      <div>
        <p>
          Som følge av revisjon av kontraktsmalene er flere av malene i UGE blitt oppdatert. Følgende maler har blitt
          oppdatert og har fått nye felter/valg i veiviser for å opprette kontrakt.
        </p>
        <ul>
          <li>Leie av areal</li>
          <ul>
            <li>Valg om kvm pris er beregnet pr år/måned/sesong</li>
          </ul>
          <li>Leie av riggplass</li>
          <ul>
            <li>Sikkerhet. Her velges det om sikkerhet er stilt med depositum eller en annen garantiform</li>
            <li>Valg om kvm pris er beregnet pr år/måned/sesong</li>
          </ul>
          <li>Leie av bolig</li>
          <li>Leie av tjenestebolig - uten boplikt</li>
          <li>Leie av tjenestebolig - med boplikt</li>
          <li>Leie av lokale på eiendom med flere brukere</li>
          <li>Leie av lokale med en bruker</li>
        </ul>
        <div>
          <b>Tillegg:</b> Alle kontraktmalene over har fått valg om kontrakten skal signeres digitalt/manuelt. Velger
          man digital signatur så vil dette stå i dokumentet, og feltene for håndsignering er fjernet.
        </div>
      </div>
      <h5 style={{ fontSize: "1.5em", marginTop: "30px" }}>Oppdatert regel om indeksjustering</h5>
      <p>
        Fremover så vil kontrakter av typen <b>Leie av areal</b> og <b>Leie av riggplass</b> bli indeksjustert allerede
        første året av kontrakten. Dette gjelder kun nye kontrakter av disse kontrakttypene og vil ikke påvirke
        eksisterende kontrakter.
      </p>
      <p>
        For eksisterende leie av rigg/areal kontrakter og andre kontrakttyper så vil de indeksjusteres først i år 2 av
        kontrakten.
      </p>
    </div>
  );
}
// For å støtte opp om enkelte nye
//           felter/valg i kontraktmalene så er veiviser for opprettelse av kontrakt er oppdatert.
