import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useActions from "store/useActions";
import {
  Button,
  Grid,
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalFooter,
  Row,
  Col,
  ControlLabel
} from "react-bootstrap";
import { getAdresseForLokasjon, getLokasjonForAdresse } from "actions/eiendomAction";
import BymMap from "../Common/Map/BymMap";
import { BymButton, BymForm } from "components/Common/Forms";
import { getKoder } from "../../actions/koderAction";
import getKoderSelector from "../../selectors/koderSelector";
import ConfirmDialog from "components/Common/ConfirmDialog";
import { getGeoJSONFromPoint } from "components/Common/Map/BymMarker";

import "./Eiendom.css";
import { Eiendom } from "interfaces";
import { getCssBakgrunnForVerifisertSaksnummer, validerArkivsaknummerWithFunc } from "utils/arkivUtils";

interface AddEiendomDialogProps {
  eiendomsdata?: Eiendom;
  show: boolean;
  onClose?: () => void | null;
  onSubmit?: (values: any) => void | null;
  segment?: string;
}

const AddEiendomDialog = ({ eiendomsdata, show = false, onClose, onSubmit, segment }: AddEiendomDialogProps) => {
  const emptyForm = {
    navn: "",
    segmentId: segment,
    adresse: "",
    postnummer: "",
    poststed: "",
    kommune: "",
    gnr: "",
    bnr: "",
    distrikt: "",
    omrade: "",
    seksjon: "",
    kategori: "",
    anleggsnummer: "",
    prosjektnummer: "",
    vann: "",
    eiendom: "",
    koordinater: {},
    visIPublikumPortal: false,
    arkivSakNummer: ""
  };

  const [eiendom, setEiendom] = useState<any>(eiendomsdata ?? emptyForm);
  const [isUpdate] = useState(!!eiendomsdata);
  const [marker, setMarker] = useState<any[]>([]);
  const [verifisertSaksnummer, setVerifisertSaksnummer] = useState<boolean | undefined>(undefined);
  const [adresseFromMap, setAdresseFromMap] = useState<any>();

  const [centerMapAlert, setCenterMapAlert] = useState<string>("");

  const segmenter = useSelector(state => getKoderSelector(state, "segmenter"));
  const actions = useActions(
    {
      getKoder
    },
    []
  );

  useEffect(() => {
    actions.getKoder("segmenter");

    if (eiendomsdata) {
      setEiendom({
        ...eiendomsdata
      });

      // marker info
      const marker = eiendomsdata?.koordinater
        ? [
            {
              kind: "geoJSON",
              data: getGeoJSONFromPoint(eiendomsdata.koordinater.x, eiendomsdata.koordinater.y),
              tooltipTitle: eiendomsdata?.Navn,
              showTooltip: eiendomsdata?.Navn ? true : false
            }
          ]
        : [];

      setMarker(marker);
    }
  }, [actions, eiendomsdata]);

  const onFormValueUpdated = (key: string, value: any) => {
    setEiendom({
      ...eiendom,
      [key]: value
    });
  };

  const centerMap = async () => {
    if (!eiendom.adresse) return;

    const lokasjon = await getLokasjonForAdresse(eiendom.adresse);
    if (lokasjon == null) {
      setCenterMapAlert(`Finner ikke punkt for adresse ${eiendom.adresse}`);
      return;
    }

    onLokasjonValgt({ lat: lokasjon?.representasjonspunkt?.lat, lng: lokasjon?.representasjonspunkt?.lon });
  };

  const onLokasjonValgt = async (lokasjon: any) => {
    setEiendom({
      ...eiendom,
      koordinater: {
        x: lokasjon.lng,
        y: lokasjon.lat,
        rings: null
      }
    });

    const marker = [
      {
        objectId: lokasjon.lng,
        kind: "geoJSON",
        data: getGeoJSONFromPoint(lokasjon.lng, lokasjon.lat),
        tooltipTitle: eiendomsdata?.Navn,
        showTooltip: eiendomsdata?.Navn ? true : false
      }
    ];

    setMarker(marker);
    const adresse = await getAdresseForLokasjon(lokasjon.lat, lokasjon.lng);
    if (!adresse) return;

    setAdresseFromMap(adresse);
  };

  const saveEiendom = () => {
    const toBeSubmitted = {
      ...eiendom
    };

    setEiendom(emptyForm);
    onSubmit?.(toBeSubmitted);
  };

  const validerSaksnummerInput = (saksnummer: string) => {
    setVerifisertSaksnummer(undefined);

    if (saksnummer) {
      validerArkivsaknummerWithFunc(
        saksnummer,
        () => setVerifisertSaksnummer(true),
        () => setVerifisertSaksnummer(false)
      );
    }
  };

  return (
    <Modal onHide={() => onClose?.()} show={show} backdrop="static" dialogClassName="add-eiendom-dialog">
      <ModalHeader closeButton>
        <ModalTitle>{isUpdate ? "Oppdater eiendom" : "Opprett eiendom"}</ModalTitle>
      </ModalHeader>

      <BymForm values={eiendom} onSubmit={saveEiendom}>
        <ModalBody>
          <Grid fluid>
            <Row>
              <Col md={6}>
                <label htmlFor="navn" className="control-label">
                  Navn
                  <input
                    className="form-control"
                    id="navn"
                    required
                    value={eiendom.navn}
                    onChange={e => onFormValueUpdated("navn", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={6}>
                <label htmlFor="segmentId" className="control-label">
                  Seksjon
                  <select
                    defaultValue={segment}
                    className="form-control"
                    required
                    value={eiendom.segmentId && segmenter.find(s => s.id === eiendom.segmentId)?.id}
                    onChange={e => onFormValueUpdated("segmentId", e.target.value)}
                  >
                    <option value="">Velg</option>
                    {segmenter.map(s => {
                      return (
                        <option key={s.id} value={s.id}>
                          {s.navn}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label htmlFor="adresse" className="control-label">
                  Adresse
                  <input
                    className="form-control"
                    value={eiendom.adresse}
                    onChange={e => onFormValueUpdated("adresse", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={2}>
                <div className="sentrer-kart-button">
                  <BymButton onClick={() => centerMap()}>Sentrer kart</BymButton>
                </div>
                {centerMapAlert && (
                  <Modal
                    style={{ width: "100%", margin: "auto", padding: "10em" }}
                    show={centerMapAlert !== ""}
                    onHide={() => false}
                  >
                    <ModalHeader>
                      <h4>Fant ikke adresse</h4>
                    </ModalHeader>
                    <ModalBody>
                      <div style={{ paddingBottom: "2em" }}>{centerMapAlert}</div>
                      <Button bsStyle="bym-standard" onClick={() => setCenterMapAlert("")}>
                        Ok
                      </Button>
                    </ModalBody>
                  </Modal>
                )}
              </Col>
              <Col md={3}>
                <label htmlFor="kategori" className="control-label">
                  Kategori
                  <input
                    className="form-control"
                    value={eiendom.kategori}
                    onChange={e => onFormValueUpdated("kategori", e.target.value)}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label htmlFor="postnummer" className="control-label">
                  Postnummer
                  <input
                    className="form-control"
                    id="postnummer"
                    value={eiendom.postnummer}
                    onChange={e => onFormValueUpdated("postnummer", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={4}>
                <label htmlFor="poststed" className="control-label">
                  Poststed
                  <input
                    className="form-control"
                    id="poststed"
                    value={eiendom.poststed}
                    onChange={e => onFormValueUpdated("poststed", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3}>
                <label htmlFor="gnr" className="control-label">
                  Gnr.
                  <input
                    className="form-control"
                    id="gnr"
                    value={eiendom.gnr}
                    onChange={e => onFormValueUpdated("gnr", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3}>
                <label htmlFor="bnr" className="control-label">
                  Bnr.
                  <input
                    className="form-control"
                    id="bnr"
                    value={eiendom.bnr}
                    onChange={e => onFormValueUpdated("bnr", e.target.value)}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <label htmlFor="kommune" className="control-label">
                  Kommune
                  <input
                    className="form-control"
                    id="kommune"
                    value={eiendom.kommune}
                    onChange={e => onFormValueUpdated("kommune", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3}>
                <label htmlFor="anleggsnummer" className="control-label">
                  Anleggsnummer
                  <input
                    className="form-control"
                    id="anleggsnummer"
                    value={eiendom.anleggsnummer}
                    onChange={e => onFormValueUpdated("anleggsnummer", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3}>
                <label htmlFor="prosjektnummer" className="control-label">
                  Prosjektnummer
                  <input
                    className="form-control"
                    id="prosjektnummer"
                    value={eiendom.prosjektnummer}
                    onChange={e => onFormValueUpdated("prosjektnummer", e.target.value)}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label htmlFor="distrikt" className="control-label">
                  Distrikt
                  <input
                    className="form-control"
                    id="distrikt"
                    value={eiendom.distrikt}
                    onChange={e => onFormValueUpdated("distrikt", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3}>
                <label htmlFor="omrade" className="control-label">
                  Område
                  <input
                    className="form-control"
                    id="omrade"
                    value={eiendom.omrade}
                    onChange={e => onFormValueUpdated("omrade", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3}>
                <label htmlFor="vann" className="control-label">
                  Vann
                  <input
                    className="form-control"
                    id="vann"
                    value={eiendom.vann}
                    onChange={e => onFormValueUpdated("vann", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3}>
                <label htmlFor="vernEiendom" className="control-label">
                  Vern eiendom
                  <input
                    className="form-control"
                    id="vernEiendom"
                    value={eiendom.vernEiendom}
                    onChange={e => onFormValueUpdated("vernEiendom", e.target.value)}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              {/* <Col md={3}>
                <label htmlFor="visIByleie" className="control-label">
                  Vis i Byleie
                  <input
                    className="form-control"
                    style={{ width: "20px" }}
                    type="checkbox"
                    id="visIByleie"
                    checked={eiendom?.visIPublikumPortal ? true : false}
                    onChange={e => onFormValueUpdated("visIPublikumPortal", e.target.checked)}
                  />
                </label>
              </Col>  */}
              <Col md={3}>
                <label htmlFor="arkivSakNummer" className="control-label">
                  Arkiv saknummer
                  <input
                    className={`form-control ${getCssBakgrunnForVerifisertSaksnummer(verifisertSaksnummer)}`}
                    id="arkivSakNummer"
                    autoComplete="off"
                    required={eiendom.visIPublikumPortal}
                    placeholder={eiendom.visIPublikumPortal ? "Påkrevd for Vis i BYleie" : ""}
                    value={eiendom?.arkivSakNummer}
                    onChange={e => onFormValueUpdated("arkivSakNummer", e.target.value)}
                  />
                </label>
              </Col>
              <Col md={3} hidden={!eiendom?.arkivSakNummer}>
                <label htmlFor="validerSaksnummer" className="control-label"></label>
                <Button
                  className="valider-button"
                  id="validerSaksnummer"
                  onClick={e => {
                    e.preventDefault();
                    validerSaksnummerInput(eiendom?.arkivSakNummer);
                  }}
                >
                  Valider saksnummer
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ControlLabel>Lokasjon</ControlLabel>
                {adresseFromMap && (
                  <ConfirmDialog
                    show
                    title="Adresse fra punkt"
                    confirmButtonText="Bruk adresse"
                    closeButtonText="Bare sett punkt"
                    onClose={() => setAdresseFromMap(undefined)}
                    onConfirm={() => {
                      setEiendom({
                        ...eiendom,
                        navn: adresseFromMap.adressetekst,
                        adresse: adresseFromMap.adressetekst,
                        postnummer: adresseFromMap.postnummer,
                        poststed: adresseFromMap.poststed,
                        kommune: adresseFromMap.kommunenavn,
                        gnr: adresseFromMap.gardsnummer,
                        bnr: adresseFromMap.bruksnummer
                      });
                      setAdresseFromMap(undefined);
                    }}
                  >
                    <p>
                      <strong>Fant følgende adresse fra punkt:</strong>
                    </p>
                    <table>
                      <tr>
                        <td>
                          <strong>Navn</strong>
                        </td>
                        <td>{adresseFromMap.adressetekst}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Adresse</strong>
                        </td>
                        <td>{adresseFromMap.adressetekst}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "40px" }}>
                          <strong>Postnummer </strong>
                        </td>
                        <td>{adresseFromMap.postnummer}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Poststed</strong>
                        </td>
                        <td>{adresseFromMap.poststed}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Kommune</strong>
                        </td>
                        <td>{adresseFromMap.kommunenavn}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Gnr</strong>
                        </td>
                        <td>{adresseFromMap.gardsnummer}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Bnr</strong>
                        </td>
                        <td>{adresseFromMap.bruksnummer}</td>
                      </tr>
                    </table>
                  </ConfirmDialog>
                )}
                <BymMap zoom={15} markers={marker} onLocationSelected={onLokasjonValgt} autoBounds />
              </Col>
            </Row>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} bsStyle="link">
            Avbryt
          </Button>
          <Button type="submit">Bekreft</Button>
        </ModalFooter>
      </BymForm>
    </Modal>
  );
};

AddEiendomDialog.propTypes = {
  eiendomsdata: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  segment: PropTypes.string
};

export default AddEiendomDialog;
