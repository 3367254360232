import { Row, Col } from "react-bootstrap";
import { BymRadioButtonsInput, BymSelectInput, BymTextInput, required } from "components/Common/Forms";
import BymFormValueWrapper from "components/Common/Forms/BymFormValueWrapper";
import BymFormConditional from "components/Common/Forms/BymFormConditional";
import UgeDateInput from "components/Common/Forms/UgeDateInput";
import { AvtaleData, UIState } from "./KontraktAvtaleUtils";
import FellesKostnader from "./FellesKostnader";
import TimeLeiePrisInfo from "./TimeLeiePrisInfo";
import PrisInfo from "./PrisInfo";
import BymCurrencyInput from "components/Common/Forms/BymCurrencyInput";

type FakturaInformasjonProps = {
  leieObjekter?: any[];
  uiState: UIState;
  currentValues?: AvtaleData;
};

const FakturaInformasjon = ({ leieObjekter = [], uiState, currentValues }: FakturaInformasjonProps) => {
  return (
    <>
      {!uiState.hideVarighet && (
        <Row>
          <Col md={12}>
            <div className="leieobjekt-bruk" style={{ padding: "15px 60px 15px 15px" }}>
              <Row>
                <Col md={8}>
                  <h4>
                    Leieforholdets varighet og oppsigelse <br />
                    <small>
                      {uiState.supportsTidsubestemt
                        ? `Velg fra og til dato for kontrakt. Hvis man ikke setter "Til dato"
                                   vil kontrakten være en tidsubestemt kontrakt. Total leie under leiesum er også kun tilgjengelig
                                   for tidsubestemte kontrakter`
                        : "Velg dato for tidsbestemt kontrakt"}
                      {currentValues?.avtaleData?.kontraktsMal === "8" && (
                        <div>
                          <br />
                          Når BYM skal inngå avtaler om utleie av bolig, vil vi i hovedsak være omfattet av lovens krav
                          om minstetid på tre år. Våre boligkontrakter skal derfor ha en minstetid på tre år, med mindre
                          et av unntakene kommer til anvendelse. Er du usikker på om unntakene kommer til anvendelse, ta
                          kontakt med Juridisk avdeling
                        </div>
                      )}
                    </small>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <UgeDateInput
                    controlId="kontrakt.gyldigFraDato"
                    label="*Fra dato"
                    placeholder=""
                    viewDate={new Date()}
                    alignRight={false}
                    rules={[required()]}
                  />
                </Col>
                <Col md={3}>
                  <UgeDateInput
                    controlId="kontrakt.utlopDato"
                    label={`${uiState.supportsTidsubestemt ? "" : "*"}Til dato`}
                    placeholder=""
                    viewDate={new Date()}
                    alignRight={false}
                    rules={uiState.supportsTidsubestemt ? [] : [required()]}
                  />
                </Col>
              </Row>
              {uiState.showFratrekk && (
                <Row>
                  <Col md={6}>
                    <BymTextInput
                      controlId="avtaleData.fratrekk"
                      label="Fratrekk"
                      rules={[required()]}
                      placeholder="måneder/uker/dager"
                    />
                  </Col>
                </Row>
              )}
              {!uiState.hideOppsigelsetid && (
                <Row>
                  <Col md={3}>
                    <BymSelectInput
                      controlId="avtaleData.oppsigelseTid"
                      label={
                        <>
                          *Antall måneder oppsigelsesfrist
                          <BymFormConditional<AvtaleData>
                            comparator={formValues => formValues?.avtaleData?.kontraktsMal === "7"}
                          >
                            <br />
                            <small style={{ paddingLeft: "10px", display: "inline-block", fontWeight: "normal" }}>
                              Velg 0 for å skjule tekst om gjensidig oppsigelsesfrist
                            </small>
                          </BymFormConditional>
                        </>
                      }
                      items={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(nr => {
                        return { id: nr, name: nr > 0 ? nr : "0" };
                      })}
                      rules={[required()]}
                    />
                  </Col>
                </Row>
              )}
              {uiState.showOvertakelse && (
                <>
                  <Row>
                    <Col md={12}>
                      <h4>Overlevering/Ikrafttredelse</h4>
                      <BymRadioButtonsInput
                        inline
                        hidelabel
                        label="Ny kontrakt eller forlengelse"
                        items={[
                          { id: "1", name: "Ny kontrakt" },
                          { id: "2", name: "Forlengelse/Fornying" }
                        ]}
                        controlId="avtaleData.overlevering"
                        rules={[required()]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <BymFormConditional comparator={(formValues: any) => formValues?.avtaleData?.overlevering === "1"}>
                      <Col md={3}>
                        <UgeDateInput
                          controlId="avtaleData.besiktigelseDato"
                          label="*Besiktigelse dato"
                          placeholder=""
                          viewDate={new Date()}
                          alignRight={false}
                          rules={[required()]}
                        />
                      </Col>
                    </BymFormConditional>
                    <BymFormConditional comparator={(formValues: any) => formValues?.avtaleData?.overlevering === "2"}>
                      <Col md={3}>
                        <UgeDateInput
                          controlId="avtaleData.forrigeKontraktDato"
                          label="*Forrige leiekontrakt ikrafttredelse"
                          placeholder=""
                          viewDate={new Date()}
                          alignRight={false}
                          rules={[required()]}
                        />
                      </Col>
                    </BymFormConditional>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      )}
      {uiState.showForskuddEtterskudd && (
        <Row>
          <Col md={8}>
            <h4>*Når skal det faktureres</h4>
            <BymRadioButtonsInput
              inline
              hidelabel
              label="Når skal det faktureres"
              items={[
                { id: "501", name: "Forskudd" },
                { id: "502", name: "Etterskudd" }
              ]}
              controlId="kontrakt.faktureringTidspunktId"
              rules={[required()]}
            />
          </Col>
        </Row>
      )}
      {uiState.showForsteBetaling && (
        <>
          <Row>
            <Col md={3}>
              <UgeDateInput
                controlId="avtaleData.forsteBetalingDato"
                label="*Første betalingsdato"
                viewDate={new Date()}
                alignRight={false}
                placeholder=""
                rules={[required()]}
              />
            </Col>
            {uiState.showForsteBetaling === "full" && (
              <Col md={6}>
                <BymTextInput
                  controlId="avtaleData.forsteBetalingPeriode"
                  label="*Første betalingsperiode"
                  placeholder="eks jan-mars"
                  rules={[required()]}
                />
              </Col>
            )}
          </Row>
        </>
      )}
      {uiState.showDepositum === "depositumEllerGaranti" && (
        <Row>
          <Col md={8}>
            <h4>*Sikkerhet</h4>
            <BymRadioButtonsInput
              inline
              hidelabel
              label="Sikkerhet"
              items={[
                { id: 1, name: "Garanti" },
                { id: 2, name: "Depositum" }
              ]}
              controlId="kontrakt.garantiTypeId"
              rules={[required()]}
            />
          </Col>
        </Row>
      )}
      <Row>
        {uiState.showDepositum === "depositumkonto" && (
          <>
            <Col md={3}>
              <BymTextInput
                controlId="avtaleData.finansinstitusjon"
                label="*Depositum finansinstitusjon"
                placeholder=""
                rules={[required()]}
              />
            </Col>
            <Col md={3}>
              <BymTextInput
                controlId="avtaleData.depositumKonto"
                label="*Depositum kontonummer"
                placeholder=""
                rules={[required()]}
              />
            </Col>
          </>
        )}
        {uiState.showDepositum === "depositumEllerGaranti" && (
          <>
            {currentValues?.kontrakt?.garantiTypeId == 2 && (
              <Col md={3}>
                <BymTextInput
                  controlId="avtaleData.depositumKonto"
                  label="*Depositum kontonummer"
                  placeholder=""
                  rules={[required()]}
                />
              </Col>
            )}
            {currentValues?.kontrakt?.garantiTypeId == 1 && (
              <Col md={3}>
                <BymTextInput
                  controlId="avtaleData.garantiform"
                  label="*Garantiform"
                  placeholder=""
                  rules={[required()]}
                />
              </Col>
            )}
          </>
        )}
        {uiState.showDepositum && !uiState.showTimeleiePris && (
          <Col md={3}>
            <BymCurrencyInput
              controlId="avtaleData.depositumSum"
              label="*Depositum sum"
              placeholder=""
              rules={[required()]}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={10}>
          <br />
          <h4>*Leiesum</h4>
          {uiState.showAarligTotalPris && (
            <>
              <p>
                Velg alternativ for fastsettelse av leiesum. Årlig leie benyttes for leieforhold over 12 måneder. Total
                leie benyttes for leieforhold under 12 måneder.
              </p>
              <BymFormValueWrapper<AvtaleData>
                mapFunction={formValues => {
                  // Total leie should only be available when user has chosen a utlopDato
                  if (uiState.supportsTidsubestemt && !formValues?.kontrakt?.utlopDato) {
                    return {
                      items: [{ id: "1", name: "Årlig leie" }]
                    };
                  }
                  return {
                    items: [
                      { id: "1", name: "Årlig leie" },
                      { id: "2", name: "Total leie" }
                    ]
                  };
                }}
              >
                <BymRadioButtonsInput
                  inline
                  hidelabel
                  label="Velg leiesum type"
                  controlId="avtaleData.leiesumType"
                  rules={[required()]}
                />
              </BymFormValueWrapper>
            </>
          )}
          {uiState.showFakturaFrekvens && (
            <>
              <br />
              <strong>Oppgi hvor ofte det skal faktureres</strong>
              <BymRadioButtonsInput
                inline
                hidelabel
                items={[
                  { id: "1", name: "Månedlig" },
                  { id: "2", name: "Kvartalsvis" },
                  { id: "3", name: "Halvårlig" },
                  { id: "4", name: "Årlig" }
                ]}
                controlId="kontrakt.betalingTerminId"
              />
            </>
          )}
        </Col>
      </Row>
      {uiState.showKvmPrisPeriode && (
        <Row>
          <br />
          <Col md={4}>
            <strong>Kvmpris er beregnet per</strong>
            <BymRadioButtonsInput
              inline
              hidelabel
              label=""
              items={[
                { id: "0", name: "År" },
                { id: "1", name: "Måned" },
                { id: "2", name: "Sesong" }
              ]}
              controlId="avtaleData.kvmPrisPeriode"
              rules={[required()]}
            />
          </Col>
        </Row>
      )}
      {uiState.showTimeleiePris ? (
        <TimeLeiePrisInfo
          uiState={uiState}
          leieObjekter={leieObjekter}
          leiesumType={currentValues?.avtaleData?.leiesumType}
          kontraktsMal={currentValues?.avtaleData?.kontraktsMal}
        />
      ) : (
        <PrisInfo uiState={uiState} leieObjekter={leieObjekter} currentValues={currentValues} />
      )}
      {uiState.showFelleskostnader && <FellesKostnader />}
    </>
  );
};

export default FakturaInformasjon;
