import { AvtaleData, UIState } from "./KontraktAvtaleUtils";
import { BymNumberInput, BymRadioButtonsInput, BymSpinnerButton, required } from "components/Common/Forms";
import { Col, Row } from "react-bootstrap";
import DownloadAvtaleButton from "./DownloadAvtaleButton";
import { useCallback, useRef, useState } from "react";

interface OpprettKontrakTabProps {
  isEditMode: boolean;
  showDownloadButton: boolean;
  showAddKontraktSpinner: boolean;
  uiState: UIState;
  currentValues?: AvtaleData;
}

export const OpprettKontraktTab = ({
  uiState,
  isEditMode,
  showDownloadButton,
  showAddKontraktSpinner,
  currentValues
}: OpprettKontrakTabProps) => {
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const downloadRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {!isEditMode && (
        <p>
          Trykk &quot;Opprett kontrakt&quot; for å opprette kontrakten. Når kontrakten er opprettet vil du kunne laste
          ned ferdig generert avtale i pdf format. Det vil ikke være mulig å endre avtaledokument etter at kontrakt er
          opprettet så sjekk forhåndsvisning oppe i høyre hjørne for å være sikker på at dokumentet ser riktig ut!
        </p>
      )}
      {isEditMode && (
        <p>
          Trykk &quot;Valider data&quot; for å validere at data er fylt inn riktig. Hvis data validerer vil du kunne
          laste ned ferdig kontrakt dokument.
        </p>
      )}
      <Row>
        {uiState.showSigneringValg && (
          <Col md={3}>
            <BymRadioButtonsInput
              label="Hvordan skal kontrakten signeres?"
              controlId="avtaleData.signeresDigitalt"
              items={[
                { id: "True", name: "Elektronisk" },
                { id: "False", name: "Manuelt" }
              ]}
              rules={[required()]}
            />
          </Col>
        )}
        {currentValues?.avtaleData?.signeresDigitalt === "False" && (
          <Col md={3}>
            <BymNumberInput
              controlId="avtaleData.antallEksemplarer"
              label="Antall underskrevne eksemplarer"
              rules={[required()]}
            />
          </Col>
        )}
      </Row>
      <br />
      <BymSpinnerButton
        style={{ display: "inline-flex" }}
        bsStyle="bym-action"
        text={isEditMode ? "Valider data" : "Opprett kontrakt"}
        type="submit"
        disabled={showDownloadButton}
        showSpinner={showAddKontraktSpinner}
        onClick={() => setTimeout(forceUpdate, 1)}
      />

      {showDownloadButton && (
        <DownloadAvtaleButton style={{ marginLeft: "10px" }}>Last ned avtale</DownloadAvtaleButton>
      )}
      <div ref={downloadRef} />
    </>
  );
};
