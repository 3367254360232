import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../helpers/actionHelper";
import etag from "../utils/etagUtils";

import { eiendomListSchema as getAllSchema, eiendomSchema as getSchema } from "../constants/schemas";

// GET EIENDOMER
export const GET_EIENDOMMER_REQUEST = "GET_EIENDOMMER_REQUEST";
export const GET_EIENDOMMER_SUCCESS = "GET_EIENDOMMER_SUCCESS";
export const GET_EIENDOMMER_FAILURE = "GET_EIENDOMMER_FAILURE";

export function getEiendommer() {
  return (dispatch, getState) => {
    dispatch({ type: GET_EIENDOMMER_REQUEST });
    return axios
      .get(api.GET_EIENDOMMER_URL, etag(getState, "eiendommer"))
      .then(handle.success(dispatch, GET_EIENDOMMER_SUCCESS, getAllSchema))
      .catch(handle.error(dispatch, GET_EIENDOMMER_FAILURE));
  };
}

export function getEiendommerBySegment(segmentId) {
  return (dispatch, getState) => {
    dispatch({ type: GET_EIENDOMMER_REQUEST });
    return axios
      .get(`${api.GET_EIENDOMMER_URL}&segment=${segmentId}`, etag(getState, "eiendommer"))
      .then(handle.success(dispatch, GET_EIENDOMMER_SUCCESS, getAllSchema))
      .catch(handle.error(dispatch, GET_EIENDOMMER_FAILURE));
  };
}

// GET EIENDOM
export const GET_EIENDOM_REQUEST = "GET_EIENDOM_REQUEST";
export const GET_EIENDOM_SUCCESS = "GET_EIENDOM_SUCCESS";
export const GET_EIENDOM_FAILURE = "GET_EIENDOM_FAILURE";

export function getEiendom(id) {
  return dispatch => {
    dispatch({ type: GET_EIENDOM_REQUEST, payload: id });
    return axios
      .get(api.GET_EIENDOM_URL(id))
      .then(handle.success(dispatch, GET_EIENDOM_SUCCESS, getSchema))
      .catch(handle.error(dispatch, GET_EIENDOM_FAILURE));
  };
}

// CREATE EIENDOM
export const CREATE_EIENDOM_REQUEST = "CREATE_EIENDOM_REQUEST";
export const CREATE_EIENDOM_SUCCESS = "CREATE_EIENDOM_SUCCESS";
export const CREATE_EIENDOM_FAILURE = "CREATE_EIENDOM_FAILURE";

export function createEiendom(eiendomData) {
  return dispatch => {
    dispatch({ type: CREATE_EIENDOM_REQUEST, payload: eiendomData });
    return axios
      .post(api.POST_EIENDOM_URL, eiendomData)
      .then(handle.success(dispatch, CREATE_EIENDOM_SUCCESS, getSchema))
      .catch(handle.error(dispatch, CREATE_EIENDOM_FAILURE));
  };
}

// UPDATE EIENDOM
export const UPDATE_EIENDOM_REQUEST = "UPDATE_EIENDOM_REQUEST";
export const UPDATE_EIENDOM_SUCCESS = "UPDATE_EIENDOM_SUCCESS";
export const UPDATE_EIENDOM_FAILURE = "UPDATE_EIENDOM_FAILURE";

export function updateEiendom(id, eiendomData) {
  return dispatch => {
    dispatch({ type: UPDATE_EIENDOM_REQUEST, payload: id });
    return axios
      .put(api.GET_EIENDOM_URL(id), eiendomData)
      .then(handle.success(dispatch, UPDATE_EIENDOM_SUCCESS, getSchema))
      .catch(handle.error(dispatch, UPDATE_EIENDOM_FAILURE));
  };
}

// FINN ADRESSE FOR EIENDOMSLOKASJON
export async function getAdresseForLokasjon(lat, long) {
  const resp = await axios.get(api.GET_ADRESSE_FOR_LOKASJON(lat, long, 50));
  return resp.data.result;
}

export async function getLokasjonForAdresse(adresse) {
  const resp = await axios.get(api.GET_LOKASJON_FOR_ADRESSE(adresse));
  return resp.data.result;
}
